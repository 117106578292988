@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');



.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  border-top-left-radius: 1rem;
  margin: 0;

}


canvas{
  cursor: pointer;
}

.dot {
  cursor: pointer;

}




.maplibregl-popup-close-button {

  border-radius: 15px;
  border: none;
  padding: 3px;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
}
.header-container {
  position: relative; /* Ensure the header is positioned relative for z-index to work */
  z-index: 10000; /* Higher z-index to be above the dropdown */
}
.header-container-menu-bar{
background-color:white;
}
.dropdown-container {
  position: fixed; /* Keep it fixed in the viewport */
  top: 120px; /* Position it at the top of the viewport */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust for the container's width */
  padding: 6px; /* Add some padding for a better look */

  border-radius: 8px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 999; /* Ensure it's above other content */
  transition: all 0.3s ease; /* Smooth transition for any changes */
  /* Add a border to enhance the container's appearance */
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Optional: Add a hover effect for interactivity */
.dropdown-container:hover {
  background: rgba(255, 255, 255, 1); /* Fully opaque on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly larger shadow on hover */
}

